import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../common/styles/colors'

export const useStylesFeedback = makeStyles((theme: Theme) =>
  createStyles({
    containerItem: {
      backgroundColor: '#fff',
      ...theme.typography.body2,
      // padding: theme.spacing(4),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      borderRadius: 7,
    },
    containerRow: {
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex'
    },
    title: {
      color: colors.black,
    },
    titleSuccess: {
      color: colors.green
    },
    titleWarning: {
      color: colors.yellow
    },
    titleError: {
      color: colors.pinkDark
    },
    progressBar: {
      backgroundColor: colors.grayBarPerformance,
    },
    wrapper: {
      padding: '50px 0px 20px 0px',
    },
    dividerCardCompentence: {
      backgroundColor: colors.purpleLight,
      height: 1,
    },
  }),
)
