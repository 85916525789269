import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/event_room_banner.png";
import TitleForm from "../../common/components/titleform";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { useDefaultStyles } from "../../common/styles/default";
import { Grid, Typography } from "@mui/material";

import StationsService from "../../services/StationsService";
import UsersService from "../../services/UsersService";
import RoomsService from "../../services/RoomsService";
import ThemesService from "../../services/ThemesService";

import IScenarioData from "../../types/Scenario";
import IChecklistData from "../../types/Checklist";
import IUsersData from "../../types/Users";
import IRoomData from "../../types/Room";

import IThemeData from "../../types/Themes";
import { useStationStyle } from "../styles/index.styles";
import ItemAppraiser from "./ItemAppraiser";
import { useModal } from "../../common/hooks/useModal";

export default function FormStation() {
  const styles = useDefaultStyles();
  const classes = useStationStyle();

  const { id, event_id } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleOpenModal, dataModal } = useModal();

  const [nameStation, setNameStation] = useState("");
  const [room_id, setRoomId] = useState("");
  const [scenario, setScenario] = useState<IScenarioData[]>([]);
  const [checklist, setChecklist] = useState<IChecklistData[]>([]);
  const [appraisers, setAppraisers] = useState<IUsersData[]>([]);
  const [rooms, setRooms] = useState<IRoomData[]>([]);
  const [checklist_id, setChecklist_id] = useState("");
  const [scenario_id, setScenario_id] = useState("");
  const [theme_id, setTheme_id] = useState("");
  const [appraiser, setAppraiser] = useState({});
  //const [appraisers_id, setAppraisers_id] = useState<string[]>([]);
  const [appraisers_id, setAppraisers_id] = useState("");
  const [themes, setThemes] = useState<IThemeData[]>([]);

  const handleScenario = (event: SelectChangeEvent) => {
    setScenario_id(event.target.value);
  };

  const handleTheme = (event: SelectChangeEvent) => {
    setTheme_id(event.target.value);
    loadByThemes(event.target.value);
  };

  const handleRoom = (event: SelectChangeEvent) => {
    setRoomId(event.target.value);
  };

  const handleChecklist = (event: SelectChangeEvent) => {
    setChecklist_id(event.target.value);
  };

  //const handleAppraiser = (event: SelectChangeEvent<typeof appraisers_id>) => {
  const handleAppraiser = (event: SelectChangeEvent) => {
    setAppraisers_id(event.target.value);
  };

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();

    if (nameStation == "") {
      toast(t("toast_msg_form_stations_name"), {
        type: "error",
      });
      return;
    }
    if (room_id == "") {
      toast(t("toast_msg_form_stations_name_room"), {
        type: "error",
      });
      return;
    }

    if (theme_id == "") {
      toast(t("toast_msg_form_stations_theme"), {
        type: "error",
      });
      return;
    }

    if (scenario_id == "") {
      toast(t("toast_msg_form_stations_scenario"), {
        type: "error",
      });
      return;
    }

    if (checklist_id == "") {
      toast(t("toast_msg_form_stations_checklist"), {
        type: "error",
      });
      return;
    }
    if (appraisers_id == "") {
      toast(t("toast_msg_form_stations_appraisers"), {
        type: "error",
      });
      return;
    }

    const dataStation = {
      name: nameStation,
      room_id: room_id,
      scenario_id: scenario_id,
      checklist_id: checklist_id,
      event_id: event_id,
      appraiser_id: appraisers_id,
      theme_id: theme_id,
    };

    if (!id) {
      StationsService.store(dataStation)
        .then((response: any) => {
          navigate("/events/form/" + event_id);
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    } else {
      StationsService.update(id, dataStation)
        .then((response: any) => {
          navigate("/events/form/" + event_id);
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadAppraisers = async () => {
    await UsersService.getAppraisers(0)
      .then((response: any) => {
        if (response) {
          setAppraisers(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadStation = async (idStation) => {
    await StationsService.getStations(idStation)
      .then((response: any) => {
        if (response) {
          let station = response.data;
          setNameStation(station.name);
          setRoomId(station.room_id);
          setAppraisers_id(station.appraiser_id);
          setTheme_id(station.theme_id);
          loadByThemes(station.theme_id);
          setScenario_id(station.scenario_id);
          setChecklist_id(station.checklist_id);
          setAppraiser(station.appraiser);
        }
      })
      .catch((e: Error) => {});
  };

  const loadRooms = async () => {
    await RoomsService.getAll()
      .then((response: any) => {
        if (response) {
          setRooms(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadThemes = async () => {
    await ThemesService.getAll(1, 0)
      .then((response: any) => {
        if (response) {
          setThemes(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadByThemes = async (id) => {
    await ThemesService.relationships(id)
      .then((response: any) => {
        if (response) {
          setChecklist(response.data.checklists);
          setScenario(response.data.scenarios);
        }
      })
      .catch((e: Error) => {});
  };
  const handleAppraiserModal = () => {
    handleOpenModal({
      displayName: "AppraisersModal",
      data: {
        idAppraiser: appraisers_id,
        appraisers: appraisers,
        setAppraiser: setAppraiser,
        setAppraisers_id: setAppraisers_id,
      },
    });
  };

  useEffect(() => {
    loadAppraisers();
    loadRooms();
    loadThemes();

    if (id) {
      loadStation(id);
    }
  }, []);

  return (
    <Box>
      <Banner
        type="small"
        image={banner}
        title={
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <SettingsOutlinedIcon sx={{ width: "3em", height: "3em" }} />
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {!id
                ? t("title_page_form_register_station")
                : t("title_page_form_register_station_edit")}
            </Typography>
          </Box>
        }
      />
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{
          height: "1000px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box className={styles.containerForm}>
          <TitleForm title={t("subtitle_form_station")} />
          <Box sx={{ mb: 2, width: "100%" }}>
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              label={t("input_label_station")}
              variant="outlined"
              value={nameStation}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNameStation(event.target.value);
              }}
            />
          </Box>

          <Grid container spacing={2} sx={{ mb: 0 }}>
            <Grid item xs={6}>
              <Box sx={{ mb: 2, width: "100%" }}>
                <FormControl fullWidth>
                  <InputLabel id="room-label">
                    {t("input_label_room")}
                  </InputLabel>
                  <Select
                    labelId="room-label"
                    label={t("input_label_room")}
                    value={room_id}
                    onChange={handleRoom}
                  >
                    {rooms.map((row) => (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ mb: 2, width: "100%" }}>
                <FormControl fullWidth>
                  <InputLabel id="theme-label">
                    {t("input_label_station_theme")}
                  </InputLabel>
                  <Select
                    labelId="theme-label"
                    label={t("input_label_station_theme")}
                    value={theme_id}
                    onChange={handleTheme}
                  >
                    {themes.map((row) => (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 0 }}>
            <Grid item xs={6}>
              {" "}
              <Box sx={{ mb: 8, width: "100%" }}>
                <FormControl fullWidth>
                  <InputLabel id="scenario-label">
                    {t("input_label_scenario")}
                  </InputLabel>
                  <Select
                    labelId="scenario-label"
                    label={t("input_label_scenario")}
                    value={scenario_id}
                    onChange={handleScenario}
                  >
                    {scenario.map((row) => (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Box sx={{ mb: 8, width: "100%" }}>
                <FormControl fullWidth>
                  <InputLabel id="checklist-label">
                    {t("input_label_checklist")}
                  </InputLabel>
                  <Select
                    labelId="checklist-label"
                    id="checklist"
                    label={t("input_label_checklist")}
                    value={checklist_id}
                    onChange={handleChecklist}
                  >
                    {checklist.map((row) => (
                      <MenuItem value={row.id} key={row.id}>
                        {row?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <TitleForm title={t("subtitle_form_station_2")} />
          <Grid container spacing={2} sx={{ mb: 0 }}>
            <Grid item xs={8}>
              <Typography>{t("text_form_station_2")}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                size="large"
                sx={{ ml: 2 }}
                onClick={handleAppraiserModal}
                className={classes.importAppraiser}
              >
                {" "}
                {t("button_add_appraiser")} +
              </Button>
            </Grid>
          </Grid>
          <ItemAppraiser
            appraiser={appraiser}
            setAppraisers_id={setAppraisers_id}
            appraisers_id={appraisers_id}
          />
        </Box>
        <Box className={styles.containerButton}>
          <Button type="submit" variant="contained" size="large">
            {" "}
            {t("btn_save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
