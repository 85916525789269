import React from "react";
import { useStyles } from "./styles";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles"; // Importação necessária para acessar os breakpoints

interface Props {
  titleBack?: string;
  titleAction?: string;
  onClickAction?: () => void;
  onClickAreaEvent?: () => void;
  isLoading?: boolean;
  handleSubmit?: any;
  idEvent?: any;
}

export default function FooterEvent({
  onClickAction,
  onClickAreaEvent,
  titleAction = "SALVAR E CONCLUIR",
  titleBack = "Voltar",
  isLoading,
  handleSubmit,
  idEvent,
}: Props) {
  const styles = useStyles();
  const theme = useTheme(); // Usando o hook useTheme para acessar os breakpoints
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      className={styles.container}
      sx={{
        [theme.breakpoints.down("sm")]: {
          //alignItems: 'space-between',
          width: "100%",
        },
      }}
    >
      {onClickAction && (
        <Button
          variant="contained"
          size="large"
          color="primary"
          sx={{
            ml: 2,
            [theme.breakpoints.between(0, 960)]: {
              marginLeft: "-20px",
            },
            [theme.breakpoints.between(960, 1200)]: {
              marginLeft: "10px",
            },
            [theme.breakpoints.up(1200)]: {
              marginLeft: "10px",
            },
          }}
          onClick={onClickAction}
          disabled={isLoading}
        >
          {isLoading ? "Carregando..." : titleAction}
        </Button>
      )}
      <Button
        variant="contained"
        size="large"
        color="secondary"
        sx={{
          ml: 2,
          [theme.breakpoints.down("sm")]: {
            paddingRight: "25px",
          },
        }}
        onClick={handleBack}
      >
        {titleBack}
      </Button>
    </Box>
  );
}
