import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";

import skillService from "../../services/SkillService";
import ISkillData from "../../types/Skill";

import { useStyles } from "../styles";
import LoaderHiplade from "../../common/components/loaderhiplade";

import colors from "../../common/styles/colors";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CardGeneralItensSkill from "./CardGeneralItensSkill";
import { useNavigate } from "react-router-dom";

export default function CardSkill({
  itemSkill,
  index,
  idChecklist,
  loadSkillsChecklist,
  removeSkillReg,
  arrSkills,
}: any) {
  const { t } = useTranslation();
  const classes = useStyles();

  const navigate = useNavigate();

  const [skills, setSkills] = useState<ISkillData[]>([]);
  const [skill, setSkill] = useState("");
  const [hasNewSkill, setHasNewSkill] = useState(false);
  const [passing_score, setPassingScore] = useState(
    !itemSkill ? "" : itemSkill?.passing_score
  );
  const [points, setPoints] = useState(!itemSkill ? "0" : itemSkill?.points);
  const [skillName, setSkillName] = useState("");
  const [open, setOpen] = useState(false);
  const [hasItem, setHasItem] = useState(
    !itemSkill ? false : !itemSkill.pivot ? false : true
  );
  const [skillRemove, setSkillRemove] = useState("id");
  const [arrFakeSkills, setArrFakeSkills] = useState(arrSkills);

  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (id: any) => {
    setSkillRemove(id);
    setOpen(true);
  };

  const removeskill = () => {
    skillService
      .remove(skillRemove, idChecklist)
      .then((response: any) => {
        setSkillRemove("");

        loadSkillsChecklist(idChecklist);
        setOpen(false);
        toast(response.data.Msg, {
          type: "success",
        });
      })
      .catch((e: Error) => {});
  };

  const saveSkill = async () => {
    let emptyValues = 0,
      invalidValues = 0,
      totalPoints = 0;
    const arr = arrFakeSkills;

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];

      if (element.skill_id == "") {
        emptyValues++;
      }

      if (element.skill_id == 0 && element.name == "") {
        emptyValues++;
      }

      if (
        element.points == "" ||
        parseInt(element.points) < 0 ||
        parseInt(element.points) > 100
      ) {
        invalidValues++;
      }

      if (
        element.passing_score == "" ||
        parseInt(element.passing_score) < 0 ||
        parseInt(element.passing_score) > 100
      ) {
        invalidValues++;
      }
    }

    if (emptyValues > 0) {
      toast(t("toast_msg_formchecklist_tpoints"), {
        type: "error",
      });
      return;
    }

    if (invalidValues > 0) {
      toast(t("toast_msg_formchecklist_passing_score"), {
        type: "error",
      });
      return;
    }

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      totalPoints = totalPoints + parseInt(element.points);
    }

    if (totalPoints != 100) {
      toast(t("toast_msg_formchecklist_skill_points"), {
        type: "error",
      });
      return;
    }

    setLoader(true);

    const arrCreate = arr.filter((itens: any) => !itens.pivot);
    const arrUpdate = arr.filter((itens: any) => itens.pivot);

    if (arrUpdate.length > 0) {
      let arrUpdateDef = formatArr(arrUpdate);
      console.log(arrUpdateDef);
      await skillService
        .updatemany(arrUpdateDef)
        .then((response: any) => {
          if (response) {
            loadSkillsChecklist(idChecklist);
            toast(response.data.Msg, {
              type: "success",
            });
          }
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }

    if (arrCreate.length > 0) {
      let arrCreateDef = formatArr(arrCreate);
      console.log(arrCreateDef);
      await skillService
        .createmany(arrCreateDef)
        .then((response: any) => {
          if (response) {
            loadSkillsChecklist(idChecklist, 1);
            toast(response.data.Msg, {
              type: "success",
            });
          }
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }

    setTimeout(function () {
      setLoader(false);
      navigate("/checklist/form/" + idChecklist);
    }, 5000);
  };

  const backSelect = () => {
    setSkill("");
    setHasNewSkill(false);
  };

  const loadSkills = async () => {
    await skillService
      .getAll()
      .then((response: any) => {
        if (response) {
          setSkills(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const formatArr = (arr: any) => {
    let arrDef: any = [];

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      let data = {
        skill_id: element?.skill_id == 0 ? "" : element?.skill_id,
        checklist_id: idChecklist,
        points: element?.points,
        passing_score: element?.passing_score,
        name: element?.skill_id == 0 ? element?.name : "",
      };

      arrDef.push(data);
    }

    return arrDef;
  };

  const handleSkill = (event: SelectChangeEvent) => {
    setSkill(event.target.value);
    if (event.target.value == "0") {
      setHasNewSkill(true);
    }

    let arrsFake = arrFakeSkills;
    arrsFake[index].skill_id = event.target.value;
    setArrFakeSkills(arrsFake);

    setArrFakeSkills((arr: any) => {
      return arr.map((item: any, index: any) => {
        return item;
      });
    });
  };

  const updateArr = (field, value) => {
    let arrsFake = arrFakeSkills;

    if (field == "skillName") {
      arrsFake[index].name = value;
    }

    if (field == "passing_score") {
      arrsFake[index].passing_score = value;
    }

    if (field == "points") {
      arrsFake[index].points = value;
    }

    setArrFakeSkills(arrsFake);

    setArrFakeSkills((arr: any) => {
      return arr.map((item: any, index: any) => {
        return item;
      });
    });
  };

  useEffect(() => {
    loadSkills();
    setArrFakeSkills(arrSkills);
  }, [index, idChecklist, arrSkills]);

  useEffect(() => {}, [hasItem]);

  return (
    <Box>
      {loader && <LoaderHiplade />}
      <Box className={classes.subContainerSkillItem}>
        <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
          <Grid
            item
            sx={{ fontWeight: "bold" }}
            xs={1}
            className={classes.titleskill}
          >
            {index < 10 ? "0" + (index + 1) : index + 1}
          </Grid>
          {itemSkill?.skill_id != "" && itemSkill?.pivot && (
            <Grid
              item
              sx={{ fontWeight: "bold" }}
              xs={4}
              className={classes.titleskill}
            >
              {itemSkill?.name}
            </Grid>
          )}
          {!itemSkill?.pivot && (
            <Grid
              item
              sx={{ fontWeight: "bold" }}
              xs={4}
              className={classes.titleskill}
            >
              {!hasNewSkill && (
                <FormControl fullWidth>
                  <InputLabel id="checklist-label"></InputLabel>
                  <Select
                    labelId="checklist-label"
                    id="checklist"
                    value={skill}
                    onChange={handleSkill}
                  >
                    {skills.map((row, index) => (
                      <MenuItem
                        value={row.id}
                        key={index}
                        sx={{ textTransform: "uppercase" }}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    <MenuItem value="0" sx={{ textTransform: "uppercase" }}>
                      {t("menu_item_new_skill")}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              {hasNewSkill && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "90%" }}>
                    <TextField
                      label="Nome da Habilidade"
                      variant="outlined"
                      fullWidth
                      value={skillName}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setSkillName(event.target.value);
                        updateArr("skillName", event.target.value);
                      }}
                    />
                  </Box>
                  <Box sx={{ ml: 3 }}>
                    <ClearIcon
                      onClick={() => backSelect()}
                      sx={{ color: colors.gray, cursor: "pointer" }}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={1}
            sx={{ textAlign: "center" }}
            className={classes.boxskillPoints}
          >
            <Box>
              <Typography
                sx={{ fontWeight: "bold", textAlign: "right", width: "100%" }}
                className={classes.titleGrade}
              >
                {t("input_label_possible_points_checklist")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ textAlign: "center" }}
            className={classes.boxskillPoints}
          >
            <Box>
              <TextField
                type="number"
                variant="outlined"
                value={points}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPoints(event.target.value);
                  updateArr("points", event.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.boxskill}>
            <Typography
              sx={{
                fontWeight: "bold",
                textAlign: "right",
                width: "100%",
              }}
              className={classes.titleGrade}
            >
              {t("input_label_grades_checklist")}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.boxskill}>
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              type="number"
              variant="outlined"
              value={passing_score}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassingScore(event.target.value);
                updateArr("passing_score", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2} className={classes.boxskillPoints}>
            <Box sx={{ pt: 3 }}>
              <Button
                variant="contained"
                sx={{ mb: 5 }}
                onClick={saveSkill}
                size="small"
                color="secondary"
              >
                {t("btn_save")}
              </Button>
            </Box>
            <Typography
              sx={{ fontWeight: "bold" }}
              className={classes.titleGrade}
            >
              <ClearIcon
                sx={{ color: colors.gray, cursor: "pointer", ml: 3 }}
                onClick={() =>
                  itemSkill?.skill_id != ""
                    ? handleClickOpen(itemSkill?.skill_id)
                    : removeSkillReg(index)
                }
              />
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      {itemSkill?.pivot && (
        <Box className={classes.containerSubColor}>
          <CardGeneralItensSkill
            idskill={itemSkill?.skill_id}
            idChecklist={idChecklist}
            total={points}
          />
        </Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("title_dialog_delete_skill")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("description_dialog_delete_skill")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("btn_dialog_no")}</Button>
          <Button onClick={removeskill} autoFocus>
            {t("btn_dialog_yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
