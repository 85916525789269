import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDefaultStyles } from "../../../common/styles/default";
import GeneralPerformanceBox from "../general-performance/GeneralPerformanceBox";
import { useDebriefings } from "../../hooks/useDebriefings";
import secureLocalStorage from "react-secure-storage";
import FeedbackResult from "./FeedbackResult";
import FooterEventAppraiserFeedback from "./FooterEventAppraiserFeedback";
//import feedbacksData from '../../mocks/debriefing_feedback.json'
import DebriefingService from "../../../services/DebriefingService";
import EvaluatorCard from "./CardEvaluator";
import { useTranslation } from "react-i18next";

export default function EventAppraiserFeedback({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  station,
  scenario,
}: any) {
  const styles = useDefaultStyles();
  const { t } = useTranslation();

  const {
    getDebriefingFeedback,
    getFeedbackByDebriefing,
    debriefing,
    feedbacksData,
    errorFeedback,
    loadingFeedback,
    successFeedback,
  } = useDebriefings();
  const auth_user_id = secureLocalStorage.getItem("id") as number;

  useEffect(() => {
    getDebriefingFeedback({ stationId, studentId: auth_user_id });
    getFeedbackByDebriefing(debriefingId);
  }, [eventId, stationId, evaluatedId, debriefingId, station, scenario]);

  return (
    <Box className={styles.container}>
      <EvaluatorCard
        name={feedbacksData?.appraiser_name}
        appraiserId={feedbacksData?.appraiser_id}
        role={t("title_label_class_evaluator")}
      />

      <FeedbackResult
        items={debriefing?.rating_items}
        eventId={eventId}
        stationId={stationId}
        evaluatedId={evaluatedId}
        debriefingId={debriefingId}
        station={station}
        scenario={scenario}
        data={feedbacksData}
      />
      <GeneralPerformanceBox
        result={{ score: feedbacksData.station_performace }}
        debriefingId={debriefingId}
      />
      <FooterEventAppraiserFeedback
        description={feedbacksData.station_consideration}
        title={t("title_final_considerations") ?? ""}
      />
    </Box>
  );
}
