// EvaluatorCard.tsx
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useStyles } from "./styles";
import AvatarHiplade from "../../../../common/components/avatarhiplade";
import { useTranslation } from "react-i18next";

interface EvaluatorCardProps {
  name: string;
  role: string;
  appraiserId: any;
}

const EvaluatorCard: React.FC<EvaluatorCardProps> = ({
  name,
  role,
  appraiserId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <AvatarHiplade
        nameUser={name}
        size="small"
        theme="gray"
        id={appraiserId}
      />
      <CardContent className={classes.content}>
        <Typography variant="subtitle1">
          <strong>{t("title_dialog_class_evaluator")}</strong> {name}
        </Typography>
      </CardContent>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="role-select-label">
          {t("title_input_label_class_evaluator")}
        </InputLabel>
        <Select
          labelId="role-select-label"
          value={role}
          label={t("title_label_class_evaluator")}
        >
          <MenuItem value={t("title_menu_item_class_evaluator")}>
            {t("title_label_class_evaluator")}
          </MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};

export default EvaluatorCard;
