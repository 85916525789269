import { makeStyles, Theme, createStyles } from '@material-ui/core'
import colors from '../../../../common/styles/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: `1px solid${colors.purple}`,
      paddingTop: 20,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    wrapper: {
        marginBottom: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
      backgroundColor: colors.purpleLightStation,
    },
    avatar: {
      marginRight: '16px',
      width: '50px',
      height: '50px',
    },
    content: {
      flex: '1',
    },
    formControl: {
      minWidth: 150,
    },
  }),
)
