import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import secureLocalStorage from "react-secure-storage";

import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/event_scenario_banner.png";

import { useDefaultStyles } from "../../common/styles/default";
import ScenarioService from "../../services/ScenarioService";
import IScenarioList from "../../types/Scenario";
import { MAXLENGTH } from "../constants";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function List() {
  const navigate = useNavigate();
  const theme = useTheme() // Usando o hook useTheme para acessar os breakpoints
  const { t } = useTranslation();
  const styles = useDefaultStyles();

  const handleAdd = () => {
    navigate("/scenarios/form");
  };

  const paginate = 1;
  const firstpage = 1;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [scenarios, setScenarios] = useState<IScenarioList>();

  const [scenarioRemove, setScenarioRemove] = useState("");

  const [open, setOpen] = React.useState(false);

  const localPermissions = secureLocalStorage
    .getItem("permissions")
    ?.toString();
  const permissions = localPermissions?.split(",");

  const handleClickOpen = (id: any) => {
    setScenarioRemove(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const npage = newPage + 1;

    setPage(newPage);
    loadScenarios(npage, paginate);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {};

  const loadScenarios = async (page: number, paginate: number) => {
    await ScenarioService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setScenarios(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const removeScenario = () => {
    ScenarioService.remove(scenarioRemove)
      .then((response: any) => {
        setScenarioRemove("");
        loadScenarios(firstpage, paginate);
        setOpen(false);
      })
      .catch((e: Error) => {});
  };

  const editScenario = (id: number) => {
    navigate("/scenarios/form/" + id);
  };

  useEffect(() => {
    loadScenarios(firstpage, paginate);
  }, []);

  return (
    <Box>
      <Banner
        type="medium"
        image={banner}
        title={t("banner_title_event_scenarios")}
        subtitle={t("banner_subtitle_event_scenarios_list")}
      />
      <Box sx={{ width: '100%',
                 position: 'relative', 
                 paddingLeft: '90px',
                 paddingRight: '250px',
                 paddingBottom: '100px',

                 [theme.breakpoints.down("sm")]: {
                  //border: '1px solid black',
                  position: 'relative',
                  ml: '25px',
                  width: '94%',
                  paddingLeft: '5px',
                  paddingRight: '30px',
                },

                [theme.breakpoints.between('sm', 'md')]: {
                  //border: '1px solid black',
                  position: 'relative',
                  ml: '25px',
                  width: '94%',
                  paddingLeft: '53px',
                  paddingRight: '53px',
                },

                [theme.breakpoints.between('md', 'lg')]: {
                  //border: '1px solid black',
                  position: 'relative',
                  ml: '25px',
                  width: '92%',
                  paddingLeft: '34px',
                  paddingRight: '30px',
                },

                [theme.breakpoints.between('lg', 'xl')]: {
                  //border: '1px solid black',
                  position: 'relative',
                  ml: '25px',
                  width: '96%',
                  paddingLeft: '65px',
                  paddingRight: '75px',
                },
       }} className={styles.containerForm}>
        {permissions?.includes("create_checklist") && (
          <Box sx={{ mb: 2, width: "10%" }}>
            <Button variant="contained" onClick={handleAdd}>
              {t("btn_add")}
            </Button>
          </Box>
        )}
        <TableContainer component={Paper} sx={{ mt: 5 }}>
          <Table sx={{ minWidth: 600 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("scenario_name")}
                </TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("scenario_theme")}
                </TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("scenario_competence")}
                </TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("scenario_goal")}
                </TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("table_label_button")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scenarios?.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.theme_name}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.competence_name}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.goal_scene.length > MAXLENGTH
                      ? row.goal_scene.slice(0, MAXLENGTH) + "..."
                      : row.goal_scene}
                  </TableCell>

                  <TableCell>
                    {permissions?.includes("update_scenario") && (
                      <EditIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => editScenario(row.id)}
                      />
                    )}
                    {permissions?.includes("delete_scenario") && (
                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleClickOpen(row.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {scenarios && scenarios?.total > rowsPerPage && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={7}
                    count={scenarios?.total}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={t("table_label_number_per_page")}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("title_dialog_delete_scenario")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("description_dialog_delete_scenario")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("btn_dialog_no")}</Button>
          <Button onClick={removeScenario} autoFocus>
            {t("btn_dialog_yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
