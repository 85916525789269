import * as React from 'react'

import { useStyles } from './styles/index.styles'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import { Button, Divider, Grid, Typography } from '@mui/material'
import Box from '@material-ui/core/Box'

type props = {
  type?: 'big' | 'student' | 'medium' | 'small',
  title?: any,
  subtitle?: any,
  subtitle_second?: any,
  image?: any,
  icon?: any,
  rounded?: any,
  divider?: any,
  colorDivider?: any,
  shadow?: any,
  button?: any,
  avatar?: any,
}

export default function Banner({
  type,
  title,
  subtitle,
  subtitle_second,
  image,
  icon,
  rounded,
  divider,
  colorDivider,
  shadow,
  button,
  avatar,
}: props) {
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {}, [])

  return (
    <Box
      className={
        type == 'big'
          ? classes.bigBanner
          : type == 'medium'
          ? classes.mediumBanner
          : type == 'student'
          ? classes.studentBanner
          : classes.smallBanner
      }
      style={{
        backgroundImage:
          type == 'big' || type == 'medium' ? `url(${image})` : '', 
        boxShadow: shadow == 1 ? '0px 4px 10px rgba(91, 28, 203, 0.5)' : ''
      }}
      sx={{ borderRadius: rounded ? rounded : ''}} 
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={11}
          className={
            type == 'big'
              ? classes.gridBigBanner
              : type == 'medium'
              ? classes.gridMediumBanner
              : classes.gridSmallBanner
          }
        >
          {type != 'student' && (
            <Box className={type == 'small' ? classes.smallContainer : ''}>
              {type == 'small' && <Box mt={2}> {icon} </Box>}
              <Typography
                className={
                  type == 'big'
                    ? classes.titleBigBanner
                    : type == 'medium'
                    ? classes.titleMediumBanner
                    : classes.titleSmallBanner
                }
                sx={{ fontWeight: 700 }}
              >
                {title}
              </Typography>
              {divider == '1' && (
                <Divider
                  className={
                    colorDivider == 'light'
                      ? classes.dividerLight
                      : classes.divider
                  }
                />
              )}
              {type != 'small' && (
                <Typography
                  className={classes.subtitleBanner}
                  sx={{ fontWeight: 700 }}
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
          )}
          {type == 'small' && button}
          {type == 'student' && (
            <Box className={classes.studentContainer}>
              <Box className={classes.subStudentContainer}>
                <Box className={classes.studentAvatar}> {avatar} </Box>
                <Box>
                  <Typography
                    className={classes.subtitleStudentBanner}
                    sx={{ fontWeight: 700 }}
                  >
                    {subtitle}
                  </Typography>
                  <Typography
                    className={classes.titleStudentBanner}
                    sx={{ fontWeight: 700 }}
                  >
                    {title}
                  </Typography>
                  <Typography className={classes.subSubtitleStudentBanner}>
                    {subtitle_second}
                  </Typography>
                </Box>
              </Box>
              {button}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
