import { Box, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStylesFeedback } from "./styles";
import colors from "../../../common/styles/colors";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";

interface ContentCardProps {
  description: string | undefined;
  type: "done" | "partially_done" | "done_wrong" | "not_done";
  score: string | undefined;
  showDivisor?: boolean | undefined;
}

export const TypeTextFeedback = ({
  type,
}: {
  type: "done" | "partially_done" | "done_wrong" | "not_done";
}) => {
  const stylesFeedback = useStylesFeedback();
  const { t } = useTranslation();

  const handleType = () => {
    switch (type) {
      case "done":
        return {
          color: stylesFeedback.titleSuccess,
          title: t("label_value_action_did"),
        };
      case "partially_done":
        return {
          color: stylesFeedback.titleWarning,
          title: t("label_value_action_half_did"),
        };
      case "done_wrong":
        return {
          color: stylesFeedback.titleError,
          title: t("label_value_action_did_wrong"),
        };
      case "not_done":
        return {
          color: stylesFeedback.titleError,
          title: t("label_value_action_didnt"),
        };
      default:
        return {
          color: stylesFeedback.titleError,
          title: t("label_value_action_didnt"),
        };
    }
  };

  const { color, title } = handleType();

  return <span className={color}>{title}</span>;
};

export default function ContentCard({
  description,
  type,
  score,
  showDivisor = true,
}: ContentCardProps) {
  const stylesFeedback = useStylesFeedback();
  const [color, setColor] = useState<any>("");
  const [title, setTitle] = useState<any>("");
  const { t } = useTranslation();

  const handleType = () => {
    if (type === "done") {
      setColor(stylesFeedback.titleSuccess);
      setTitle(t("label_value_action_did"));
    } else if (type === "partially_done") {
      setColor(stylesFeedback.titleWarning);
      setTitle(t("label_value_action_half_did"));
    } else if (type === "done_wrong") {
      setColor(stylesFeedback.titleError);
      setTitle(t("label_value_action_did_wrong"));
    } else {
      setColor(stylesFeedback.titleError);
      setTitle(t("label_value_action_didnt"));
    }
  };

  // const typeItem = handleType()

  useEffect(() => {
    handleType();
  }, [color, title]);

  return (
    <Stack>
      <Stack className={stylesFeedback.wrapper}>
        <Typography className={stylesFeedback.title}>{description}</Typography>
        <Stack flexDirection={"row"} spacing={4} alignItems={"center"}>
          <Typography
            sx={{ fontWeight: "bold" }}
            className={stylesFeedback.title}
          >
            <TypeTextFeedback type={type} /> {"  "}({score} {t("title_points")})
          </Typography>
        </Stack>
      </Stack>
      {showDivisor && (
        <Divider className={stylesFeedback.dividerCardCompentence}></Divider>
      )}
    </Stack>
  );
}
